import React,{useState, useEffect} from 'react'
import axios from 'axios'
import MatchCard from './MatchCard';
import GoogleAds from '../../GoogleAds';
import PopUp from '../../PopUp';
function Schedule() {
  const [matchData, setMatchData] = useState(null)
  const [selectedTab, setSelectedTab] = useState('Upcoming');
  useEffect(() => {
    const getSchedule = async() => {
        try {
            let matchSchedule = await axios("https://cricket.unibots.in/get_schedule")
            // console.log("matchSchedule", matchSchedule.data.data)
            setMatchData(matchSchedule.data.data)
          
            

        } catch (error) {
            console.log(error)
        }
    }
    getSchedule()
  
},[])

const filterMatches = (status) => {
  console.log("status", status)
  let filtermatches = matchData?.filter(match => match.status.toUpperCase() === status.toUpperCase());
 
  if(filtermatches?.length === 0){
    console.log("no match found")
    return [{noLive:true}]
  }
  return filtermatches  
}

const renderMatchCard = (match) => {
  // <div key={match.match_id} className="p-4 bg-purple-900 rounded-lg shadow-md mb-4">
  //   <div className="flex justify-between items-center">
  //     <div>
  //       <h3 className="text-lg font-semibold text-white">{match.homeTeam} vs {match.awayTeam}</h3>
  //       <p className="text-sm text-gray-400">{match.match_name}</p>
  //     </div>
  //     <div>
  //       <p className="text-sm text-gray-400">{match.match_startDateTime}</p>
  //     </div>
  //   </div>
  //   <div className="mt-2">
  //     <p className="text-sm text-gray-400">{match.status === "COMPLETED" ? match.ECo : "Yet to bat"}</p>
  //   </div>
  // </div>


  return <div className='my-2'>
    {match.noLive ? <MatchCard matchdata={match} fromschedule={true} noLive={true} /> : <MatchCard matchdata={match} fromschedule={true} />}
    
  </div>
}

  return (
    
   
    matchData &&
    <>
   <PopUp />
    <div className="container mx-auto p-4 mt-4 ">
    <div className="max-w-[480px] max-h-[320px] mobile-width flex justify-center mb-6">

<GoogleAds />
</div>
    <div className="mb-4">
      <div className="flex space-x-4">
        {['Live', 'Upcoming', 'Completed'].map(tab => (
          <button
            key={tab}
            onClick={() => setSelectedTab(tab)}
            className={`px-4 py-2 rounded-t-lg ${selectedTab === tab ? 'bg-purple-700 text-white' : 'bg-gray-300 text-gray-700'}`}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
    <div className='border-[1px] border-indigo-400 rounded-lg p-4'>
      {selectedTab === 'Live' && filterMatches('LIVE').map(match => renderMatchCard(match)) }
      {selectedTab === 'Upcoming' && filterMatches('UPCOMING').map(match => renderMatchCard(match))}
      {selectedTab === 'Completed' && filterMatches('COMPLETED').map(match => renderMatchCard(match))}
    </div>
  </div>
    </> 
    
  )
}

export default Schedule