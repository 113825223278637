import React, { useState, useEffect } from "react";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import {
  Afghanistan,
  Australia,
  Bangladesh,
  India,
  Ireland,
  Namibia,
  Nepal,
  Netherlands,
  NewZealand,
  Oman,
  SouthAfrica,
  Uganda,
  USA,
  WestIndies,
  Scotland,
  England,
  Papu,
  Pakistan,
  SriLanka,
  Canada,
  pbks,
  rcb,
  srh,
  rr,
  dc,
  kkr,
  lsg,
  mi,
  csk,
  gt,
} from "../../Assets/flags/flags";

import { useHistory } from "react-router-dom";
function MatchCard({ matchdata, fromschedule, noLive }) {
  const trackEvent = useAnalyticsEventTracker();
  const history = useHistory();
  let {
    match_name = "", // Default to an empty string if match_name is not present
    awayTeam = "", // Default to an empty string if awayTeam is not present
    homeTeam = "", // Default to an empty string if homeTeam is not present
    startDateTime = "", // Default to an empty string if startDateTime is not present
    title = "", // Default to an empty string if title is not present
    ECo: result = "", // Default to an empty string if ECo is not present, renaming to result
    Tr1C1: homeTeamScore = 0, // Default to 0 if Tr1C1 is not present, renaming to homeTeamScore
    Tr2C1: awayTeamScore = 0, // Default to 0 if Tr2C1 is not present, renaming to awayTeamScore
    Tr1CW1: homeTeamWicket = 0, // Default to 0 if Tr1CW1 is not present, renaming to homeTeamWicket
    Tr2CW1: awayTeamWicket = 0, // Default to 0 if Tr2CW1 is not present, renaming to awayTeamWicket
    Tr1CO1: homeover = 0, // Default to 0 if Tr1CO1 is not present, renaming to homeover
    Tr2CO1: awayover = 0, // Default to 0 if Tr2CO2 is not present, renaming to awayover
  } = matchdata;
  if (result.includes("win")) {
    result = result.replace("win", "won");
  }
  let imgHome = null;

  switch (homeTeam) {
    case "New Zealand":
      imgHome = NewZealand;
      break;
    case "South Africa":
      imgHome = SouthAfrica;
      break;
    case "United States":
      imgHome = USA;
      break;
    case "West Indies":
      imgHome = WestIndies;
      break;
    case "Papua New Guinea":
      imgHome = Papu;
      homeTeam = "PPG";
      break;
    case "Sri Lanka":
      imgHome = SriLanka;
      break;
    case "Afghanistan":
      imgHome = Afghanistan;
      break;
    case "Australia":
      imgHome = Australia;
      break;
    case "Bangladesh":
      imgHome = Bangladesh;
      break;
    case "India":
      imgHome = India;
      break;
    case "Ireland":
      imgHome = Ireland;
      break;
    case "Namibia":
      imgHome = Namibia;
      break;
    case "Nepal":
      imgHome = Nepal;
      break;
    case "Netherlands":
      imgHome = Netherlands;
      break;
    case "Oman":
      imgHome = Oman;
      break;
    case "Uganda":
      imgHome = Uganda;
      break;
    case "Pakistan":
      imgHome = Pakistan;
      break;
    case "Canada":
      imgHome = Canada;
      break;
    case "Scotland":
      imgHome = Scotland;
      break;
    case "England":
      imgHome = England;
      break;
    case "Gujarat Titans":
      imgHome = gt;
      break;
    case "Punjab Kings":
      imgHome = pbks;
      break;
    case "Rajasthan Royals":
      imgHome = rr;
      break;
    case "Kolkata Knight Riders":
      imgHome = kkr;
      break;
    case "Sunrisers Hyderabad":
      imgHome = srh;
      break;
    case "Lucknow Super Giants":
      imgHome = lsg;
      break;
    case "Royal Challengers Bangalore":
      imgHome = rcb;
      break;
    case "Chennai Super Kings":
      imgHome = csk;
      break;
    case "Delhi Capitals":
      imgHome = dc;
      break;
    case "Mumbai Indians":
      imgHome = mi;
      break;
    default:
      imgHome = null;
  }
  let imgAway;
  switch (awayTeam) {
    case "New Zealand":
      imgAway = NewZealand;
      break;
    case "South Africa":
      imgAway = SouthAfrica;
      break;
    case "United States":
      imgAway = USA;
      break;
    case "West Indies":
      imgAway = WestIndies;
      break;
    case "Papua New Guinea":
      imgAway = Papu;
      awayTeam = "PPG";
      break;
    case "Sri Lanka":
      imgAway = SriLanka;
      break;
    case "Afghanistan":
      imgAway = Afghanistan;
      break;
    case "Australia":
      imgAway = Australia;
      break;
    case "Bangladesh":
      imgAway = Bangladesh;
      break;
    case "India":
      imgAway = India;
      break;
    case "Ireland":
      imgAway = Ireland;
      break;
    case "Namibia":
      imgAway = Namibia;
      break;
    case "Nepal":
      imgAway = Nepal;
      break;
    case "Netherlands":
      imgAway = Netherlands;
      break;
    case "Oman":
      imgAway = Oman;
      break;
    case "Uganda":
      imgAway = Uganda;
      break;
    case "Pakistan":
      imgAway = Pakistan;
      break;
    case "Canada":
      imgAway = Canada;
      break;
    case "Scotland":
      imgAway = Scotland;
      break;
    case "England":
      imgAway = England;
      break;
    case "Gujarat Titans":
      imgAway = gt;
      break;
    case "Punjab Kings":
      imgAway = pbks;
      break;
    case "Rajasthan Royals":
      imgAway = rr;
      break;
    case "Kolkata Knight Riders":
      imgAway = kkr;
      break;
    case "Sunrisers Hyderabad":
      imgAway = srh;
      break;
    case "Lucknow Super Giants":
      imgAway = lsg;
      break;
    case "Royal Challengers Bangalore":
      imgAway = rcb;
      break;
    case "Chennai Super Kings":
      imgAway = csk;
      break;
    case "Delhi Capitals":
      imgAway = dc;
      break;
    case "Mumbai Indians":
      imgAway = mi;
      break;
    default:
      imgAway = null;
  }

  const convertToIST = (datetimeInput) => {
    // Convert input to string if it's not already a string
    const datetimeStr = String(datetimeInput);

    // Parse the datetime string into components
    const year = parseInt(datetimeStr.slice(0, 4), 10);
    const month = parseInt(datetimeStr.slice(4, 6), 10) - 1; // Months are zero-indexed in JavaScript
    const day = parseInt(datetimeStr.slice(6, 8), 10);
    const hour = parseInt(datetimeStr.slice(8, 10), 10);
    const minute = parseInt(datetimeStr.slice(10, 12), 10);
    const second = parseInt(datetimeStr.slice(12, 14), 10);

    // Create a new Date object in UTC
    const dateUTC = new Date(Date.UTC(year, month, day, hour, minute, second));

    // Convert to IST by adding 5 hours and 30 minutes
    const IST_OFFSET = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;
    const dateIST = new Date(dateUTC.getTime() + IST_OFFSET);

    // Get date components
    const istDay = dateIST.getDate();
    const istMonth = dateIST.toLocaleString("default", { month: "long" });
    const istHour = dateIST.getHours();
    const istMinute = dateIST.getMinutes().toString().padStart(2, "0"); // Ensure two digits
    const istPeriod = istHour >= 12 ? "PM" : "AM";
    const istHour12 = istHour % 12 || 12; // Convert 24-hour format to 12-hour format

    // Format the date string
    const formattedDate = `${istDay} ${istMonth} ${istHour12}:${istMinute} ${istPeriod} IST`;
    return formattedDate;
  };

  const istDate = convertToIST(startDateTime);
  //   console.log(istDate); // Outputs the date in IST
  const showPoints = () => {
    console.log("show points called");
    trackEvent(
      "Points Table Button Clicked",
      "User clicked on Points Button",
      "Points button"
    );
    history.push("/pointstable");
  };
  const showSchedule = () => {
    trackEvent(
      "Schedule Button Clicked",
      "User clicked on Schedule Button",
      "Schedule button"
    );
    history.push("/schedule");
  };
  return noLive ? (
    <>
      <div className="mt-6 flex justify-start items-center text-white text-2xl">
        No Live Matches
      </div>
    </>
  ) : matchdata.status === "UPCOMING" ? (
    <div className="flex flex-col  items-center border-[1px] border-indigo-600 text-white  p-4  rounded-xl ">
      <div className="flex justify-between w-full text-xs p-2 leading-4">
        <h1>IPL 2025</h1>
        <p>T20, {match_name}</p>
      </div>
      <div className="flex flex-col w-full justify-between  items-center bg-[#190039] my-2 rounded-xl  ">
        <div
          style={{
            borderBottomColor: "#3E51B5",
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          }}
          className="flex w-full justify-between  items-center"
        >
          <div className="flex flex-col w-[100%] py-4">
            <div className="flex justify-center items-center gap-4 ">
              <img className="h-[30px] w-[30px]" src={imgHome || homeTeam} />
              <p>{homeTeam}</p>
            </div>
            <div className="flex justify-center mt-2 p-2  text-xs leading-4">
              <h1>Yet to bat</h1>
            </div>
          </div>
          <div
            style={{
              height: "50px",
              width: "1px",
              backgroundColor: "#3E51B5",
              margin: "0 10px",
            }}
          >
            {/* <hr  /> */}
          </div>
          <div className="flex flex-col w-[100%]">
            <div className="flex justify-center items-center gap-4 ">
              <p>{awayTeam}</p>
              <img className="h-[30px] w-[30px]" src={imgAway || awayTeam} />
            </div>
            <div className="flex justify-center mt-2 p-2 text-xs leading-4">
              <h1>Yet to bat</h1>
            </div>
          </div>
        </div>
        <div className="flex justify-center p-4 minh-[30px] text-xs leading-4 font-medium">
          <div>Match starts on {istDate}</div>
        </div>
      </div>
      {!fromschedule && (
        <div className="flex justify-around w-full mt-2">
          <button
            className="text-xs leading-4 text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => showSchedule()}
          >
            Schedule
          </button>

          <button
            type="button"
            className="text-xs leading-4 text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900"
            onClick={showPoints}
          >
            Points Table
          </button>
        </div>
      )}
    </div>
  ) : matchdata.status === "COMPLETED" ? (
    <>
      <div className="flex flex-col text-white items-center border-[1px] border-indigo-600  p-4  rounded-xl ">
        <div className="flex justify-between w-full text-xs p-2 leading-4">
          <h1>IPL 2025</h1>
          <p>T20, {match_name}</p>
        </div>
        <div className="flex flex-col w-full justify-between  items-center bg-[#190039] my-2 rounded-xl  ">
          <div
            style={{
              borderBottomColor: "#3E51B5",
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
            }}
            className="flex w-full justify-between  items-center"
          >
            <div className="flex flex-col w-[100%] py-4">
              <div className="flex justify-center items-center gap-4 ">
                <img className="h-[30px] w-[30px]" src={imgHome || homeTeam} />
                <p>{homeTeam}</p>
              </div>
              <div className="flex justify-center mt-2 p-2 gap-2 text-xs leading-4">
                <div className="font-bold">
                  {homeTeamScore}/{homeTeamWicket}
                </div>
                <div>{` (${homeover}/20 ov)`}</div>
              </div>
            </div>
            <div
              style={{
                height: "50px",
                width: "1px",
                backgroundColor: "#3E51B5",
                margin: "0 10px",
              }}
            >
              {/* <hr  /> */}
            </div>
            <div className="flex flex-col w-[100%]">
              <div className="flex justify-center items-center gap-4 ">
                <p>{awayTeam}</p>
                <img className="h-[30px] w-[30px]" src={imgAway || awayTeam} />
              </div>
              <div className="flex justify-center mt-2 p-2 gap-2 text-xs leading-4">
                <div className="font-bold">
                  {awayTeamScore}/{awayTeamWicket}
                </div>
                <div>{` (${awayover}/20 ov)`}</div>
              </div>
            </div>
          </div>
          <div className="flex justify-center p-4 minh-[30px] text-xs leading-4 font-bold">
            <div>{result}</div>
          </div>
        </div>
        {!fromschedule && (
          <div className="flex justify-around w-full mt-2">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 leading-4"
              onClick={() => showSchedule()}
            >
              Schedule
            </button>
            <button
              type="button"
              className="text-xs leading-4 text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900"
              onClick={() => showPoints()}
            >
              Points Table
            </button>
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      <div className="flex flex-col text-white items-center border-[1px] border-indigo-600  p-4  rounded-xl ">
        <div className="flex justify-between w-full text-xs p-2 leading-4">
          <h1>IPL 2025</h1>
          <img
            className="w-[50px] h-[20px]"
            src="https://6494.sports.quizzop.com/_next/image?url=https%3A%2F%2Fstatic.criczop.com%2Fcriczop%2Fassets%2Fimages%2Fszp_home_live_image.png&w=96&q=100"
          />
          <p>T20, {match_name}</p>
        </div>
        <div className="flex flex-col w-full justify-between  items-center bg-[#190039] my-2 rounded-xl  ">
          <div
            style={{
              borderBottomColor: "#3E51B5",
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
            }}
            className="flex w-full justify-between  items-center"
          >
            <div className="flex flex-col w-[100%] py-4">
              <div className="flex justify-center items-center gap-4 ">
                <source srcset="image.avif" type="image/avif" />
                <source srcset="image.webp" type="image/webp" />
                <img className="h-[30px] w-[30px]" src={imgHome || homeTeam} />
                <p>{homeTeam}</p>
              </div>
              {homeTeamScore === "none" && homeover === "none" ? (
                <div className="flex justify-center mt-2 p-2  text-xs leading-4">
                  <h1>Yet to bat</h1>
                </div>
              ) : (
                <div className="flex justify-center mt-2 p-2 gap-2 text-xs leading-4">
                  <div className="font-bold">
                    {homeTeamScore}/{homeTeamWicket}
                  </div>
                  <div>{` (${homeover}/20 ov)`}</div>
                </div>
              )}
            </div>
            <div
              style={{
                height: "50px",
                width: "1px",
                backgroundColor: "#3E51B5",
                margin: "0 10px",
              }}
            >
              {/* <hr  /> */}
            </div>
            <div className="flex flex-col w-[100%]">
              <div className="flex justify-center items-center gap-4 ">
                <p>{awayTeam}</p>
                <img className="h-[30px] w-[30px]" src={imgAway || awayTeam} />
              </div>
              {awayTeamScore === "none" && awayover === "none" ? (
                <>
                  <div className="flex justify-center mt-2 p-2  text-xs leading-4">
                    <h1>Yet to bat</h1>
                  </div>
                </>
              ) : (
                <div className="flex justify-center mt-2 p-2 gap-2 text-xs leading-4">
                  <div className="font-bold">
                    {awayTeamScore}/{awayTeamWicket}
                  </div>
                  <div>{` (${awayover}/20 ov)`}</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-center p-4 minh-[30px] text-xs leading-4 font-bold">
            <div>{result}</div>
          </div>
        </div>
        {!fromschedule && (
          <div className="flex justify-around w-full mt-2">
            <button
              className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 leading-4"
              onClick={() => showSchedule()}
            >
              Schedule
            </button>
            <button
              type="button"
              className="text-xs leading-4 text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900"
              onClick={() => showPoints()}
            >
              Points Table
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default MatchCard;
