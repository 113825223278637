"use client";

import { useState, useEffect } from "react";
import axios from "axios";
import GoogleAds from "../../GoogleAds";
import PopUp from "../../PopUp";

function PointsTable() {
  const [points, setPoints] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPoints = async () => {
      try {
        const teamPoints = await axios("https://cricket.unibots.in/get_points");
        console.log("teamPoints", teamPoints);

        const sortedData = teamPoints.data.data.sort((a, b) => {
          if (a.name === "Group 1" || a.name === "Group 2") return 1;
          if (b.name === "Group 1" || b.name === "Group 2") return -1;
          return 0;
        });
        console.log("sortedData", sortedData);
        setPoints(sortedData);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    getPoints();
  }, []);

  return (
    <div className="max-w-[520px] scrollhide lgm:max-w-[360px] md:w-full md:min-w-full max-h-screen flex flex-col gap-4 py-4 px-3 items-center box-border bg-[#0f0025] rounded-lg shadow-lg">
      <div className="max-w-[480px] max-h-[320px] mobile-width">
        <GoogleAds />
      </div>

      <div className="w-full font-black py-4 px-4 text-lg leading-tight bg-[#20004A] text-white rounded-t-lg shadow-md flex items-center justify-center">
        <h1 className="uppercase text-center">Points Table</h1>
      </div>

      <PopUp />

      {loading ? (
        <div className="w-full flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      ) : (
        <div className="container mx-auto px-2">
          {points &&
            points.map((group, groupIndex) => (
              <div key={groupIndex} className="mb-8">
                <h2 className="text-xl font-bold mb-3 text-white bg-[#3a0075] py-2 px-4 rounded-t-lg">
                  {group.name}
                </h2>
                <div className="overflow-x-auto rounded-b-lg shadow-md">
                  <table className="min-w-full bg-[#190039] text-white border-collapse">
                    <thead>
                      <tr className="bg-[#270052] border-b border-[#4a0096]">
                        <th className="py-3 px-4 text-left font-semibold">
                          Team
                        </th>
                        <th className="py-3 px-4 text-center font-semibold">
                          M
                        </th>
                        <th className="py-3 px-4 text-center font-semibold">
                          W
                        </th>
                        <th className="py-3 px-4 text-center font-semibold">
                          L
                        </th>
                        <th className="py-3 px-4 text-center font-semibold">
                          D
                        </th>
                        <th className="py-3 px-4 text-center font-semibold">
                          NRR
                        </th>
                        <th className="py-3 px-4 text-center font-semibold">
                          PTS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.team.map((team, teamIndex) => (
                        <tr
                          key={teamIndex}
                          className={`
                          border-b border-[#3a0075] hover:bg-[#2a0060] transition-colors
                          ${
                            teamIndex % 2 === 0
                              ? "bg-[#1d004a]"
                              : "bg-[#190039]"
                          }
                        `}
                        >
                          <td className="py-3 px-4 font-medium">{team.Tnm}</td>
                          <td className="py-3 px-4 text-center">{team.pld}</td>
                          <td className="py-3 px-4 text-center text-green-400">
                            {team.win}
                          </td>
                          <td className="py-3 px-4 text-center text-red-400">
                            {team.lst}
                          </td>
                          <td className="py-3 px-4 text-center">{team.drw}</td>
                          <td className="py-3 px-4 text-center font-mono">
                            {team.nrr}
                          </td>
                          <td className="py-3 px-4 text-center font-bold text-yellow-300">
                            {team.pts}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default PointsTable;
