import React, { useState, useEffect, useRef } from "react";
import "tailwindcss/tailwind.css";
import axios from "axios";
import MatchCard from "./MatchCard";

const Slider = ({}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [matchData, setMatchData] = useState(null);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef(null);

  useEffect(() => {
    if (matchData) {
      const container = containerRef.current;
      const cardWidth = container.scrollWidth / matchData?.length;
      const scrollPosition = cardWidth * currentIndex;
      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
      console.log("scrollPosition", scrollPosition);
    }
  }, [currentIndex, matchData]);
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? matchData?.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === matchData?.length - 1 ? 0 : prevIndex + 1
    );
  };

  console.log("current index", currentIndex);

  useEffect(() => {
    const getSchedule = async () => {
      try {
        let matchSchedule = await axios(
          "https://cricket.unibots.in/get_schedule"
        );
        // console.log("matchSchedule", matchSchedule)
        setMatchData(matchSchedule.data.data);
        for (let i = 0; i < matchSchedule.data.data.length; i++) {
          console.log("match status", matchSchedule.data.data[i].status);
          if (
            matchSchedule.data.data[i].status === "LIVE" ||
            matchSchedule.data.data[i].status === "Start delayed"
          ) {
            console.log("inside current index", i);
            setCurrentIndex(i);
            break;
          } else if (matchSchedule.data.data[i].status === "UPCOMING") {
            setCurrentIndex(i);
            break;
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    getSchedule();
  }, []);
  return (
    <>
      {loading ? (
        <div className="text-white flex justify-center items-center">
          Loading...
        </div>
      ) : (
        <></>
      )}
      <div className="relative w-[90%] bg-[#1D1455]">
        <div className="absolute  justify-between top-0 left-0 bottom-0 right-[-10px] flex w-full items-center">
          <div
            className="cursor-pointer 0 h-full min-w-8 flex justify-start items-center  z-50 horizontal-scroll-bg-left relative left-[-24px] "
            onClick={prevSlide}
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                class=""
              >
                <path
                  d="M15 30C6.71573 30 -1.90735e-06 23.2843 -1.90735e-06 15C-1.90735e-06 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M14.9969 15.0002L18.2578 11.5219L16.6273 9.78271L11.7361 15.0002L16.6273 20.2175L18.2578 18.4784L14.9969 15.0002Z"
                  fill="#2C2C2C"
                ></path>
              </svg>
            </div>
          </div>
          <div
            className="cursor-pointer h-full min-w-8 flex justify-end items-center  z-50 horizontal-scroll-bg-right  relative right-[-24px]"
            onClick={nextSlide}
          >
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                class="rotate-180"
              >
                <path
                  d="M15 30C6.71573 30 -1.90735e-06 23.2843 -1.90735e-06 15C-1.90735e-06 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M14.9969 15.0002L18.2578 11.5219L16.6273 9.78271L11.7361 15.0002L16.6273 20.2175L18.2578 18.4784L14.9969 15.0002Z"
                  fill="#2C2C2C"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div
          className="flex scroll scroll snap-x snap-mandatory  gap-5 hide-scroll-bar overflow-hidden w-[100%]  "
          ref={containerRef}
        >
          {matchData &&
            matchData?.map((card, index) => (
              <div
                key={index}
                className={`snap-center  flex-shrink-0 transition-transform duration-300   w-full ${
                  currentIndex === index ? "scale-100" : "scale-90"
                }`}
              >
                <MatchCard matchdata={card} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Slider;
